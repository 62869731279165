import React, { useState, useEffect, Suspense } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../provider";
import * as api from "../../../services/services";
import userIcon from "../../../assets/images/icons/user-color-icon.svg";
import reportsIcon from "../../../assets/images/icons/invoice-color-icon.svg";
import settingsIcon from "../../../assets/images/icons/settings-color-icon.svg";
import moment from "moment";
import Card from "../../../components/Card/Card";
import Loading from "../../../components/Loading/Loading";
import "./account.scss";
import ModalBox from "../../../components/ModalBox/ModalBox";
import notificationIcon from "../../../assets/images/icons/notification-color-icon.svg";
import Snackbar from "../../../components/Snackbar/Snackbar";

/**
 * @description Éste es el componente que actúa como contenedor de todos los componentes en la ruta /Paciente/Cuenta
 */

const Account = () => {
	/////////////////////////////
	// Configuración del componente
	/////////////////////////////

	const options = [
		{
			key: "a1",
			title: "Datos Personales",
			icon: userIcon,
			link: "/Paciente/Cuenta/Datos",
		},
		{
			key: "a2",
			title: "Facturas y Presupuestos",
			icon: reportsIcon,
			link: "/Paciente/Cuenta/FacturaPresupuesto",
		},
		{
			key: "a3",
			title: "Ajustes",
			icon: settingsIcon,
			link: "/Paciente/Cuenta/Ajustes",
		},
	];

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const { state, listOfClinic, setUserToken, setIsClinicSelected, setPlanAmigo } = useAuth();
	const [dataAlerts, setDataAlerts] = useState(undefined);
	const [hasMoreClinic, setHasMoreClinic] = useState(false);
	const [toggleModalMoreClinic, setToggleModalMoreClinic] = useState(false);
	const [toggleModalLoadData, setToggleModalLoadData] = useState(false);
	const [labelClinicSelected, setLabelClinicSelected] = useState("");
	const history = useHistory();

	/**
	 * @see {getDataAsync}
	 * @description Cuando se construye el componente se ejecuta la función
	 *
	 */

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (listOfClinic.length > 1 && !state.isClinicSelected) {
				setHasMoreClinic(true);
				setToggleModalMoreClinic(true);
			} else {
				getSatisfactionPoll();
				getDataAsync();
			}
		}

		return () => {
			mounted = false;
		};
	}, []);

	/////////////////////////////
	// Gestión de eventos y servicios
	/////////////////////////////

	/**
	 * @description Llamada al backend que comprueba si el usuario tiene que recibir la encuesta de satisfacción
	 */

	async function getSatisfactionPoll() {
		try {
			const response = await api.getOperations(state.user);
			if (!response.idPaciente) {
				return;
			} else {
				history.push("/Paciente/EncuestaSatisfaccion");
			}
		} catch (error) {
			if (error.message === "-4") setError("Parametros de entrada incorrectos");
			if (error.message === null) setError("Parametros de entrada incorrectos");
			if (error.status === "401") setError("No tiene autorización para realizar esta operación");
			if (error.status === "204")
				setError("Hubo un error en la ejecución de tu peticion. Vuelve a intentarlo");
		}
	}

	/**
	 * @description Hace una llamada a la api. Esta llamada hace un GET para conseguir los detalles del usuario.
	 * También hace un GET para conseguir los avisos del paciente
	 *
	 * Estas llamadas se hacen únicamente si no hay datos en el estado de la aplicación
	 */

	async function getDataAsync() {
		if (!state.userData) {
			try {
				let alerts = await api.getAlerts(state.user);
				setDataAlerts(alerts);
				setLoading(alerts ? false : true);
			} catch (error) {
				if (error.message === "-4") setError("Parametros de entrada incorrectos");
				if (error.message === null) setError("Parametros de entrada incorrectos");
				if (error.status === "401") setError("No tiene autorización para realizar esta operación");
				if (error.status === "204")
					setError("Hubo un error en la ejecución de tu peticion. Vuelve a intentarlo");
			}
		}
	}

	async function setDataUser(clinicSelected) {
		setUserToken(clinicSelected.UserToken)
		localStorage.setItem('userToken', clinicSelected.UserToken)
	}

	/**
	 *
	 * @param {object} e Evento del click
	 * @param {string || number} id Id de la alerta que se tiene que eliminar del array
	 * @param {string} alertType Tipo de alerta (Incoming o appointment) para redirigir a la función al array correspondiente
	 * @description Esta función se encarga de borrar las alertas cuando se clicka aceptar en el modal de alertas. +
	 * @description Modifica el array de estado local
	 */

	const eraseAlert = (id, alertType) => {
		const filteredAlerts = dataAlerts[alertType].filter((alert) => alert.id !== id);
		setDataAlerts({ ...dataAlerts, [alertType]: filteredAlerts });
	};

	const goToClinic = async (clinicSelected) => {
		setLabelClinicSelected(clinicSelected.ClinicName);
		setToggleModalMoreClinic(false);
		setToggleModalLoadData(true);
		await setDataUser(clinicSelected);
		await getSatisfactionPoll();
		await getDataAsync();
		let response = await api.getUserData(state.user);
		await setPlanAmigo(response.planAmigo);
		setIsClinicSelected(clinicSelected.ClinicName);
		localStorage.setItem("isClinicSelected", clinicSelected.ClinicName);
		setToggleModalLoadData(false);
	}
	/////////////////////////////
	// Funciones de renderizado
	/////////////////////////////

	/**
	 *
	 * @description Una vez que se han conseguido los detalles del paciente en {getDataAsync}
	 * esta función se encarga de generar un modal si hay alertas o citas
	 */

	const renderAlerts = (alertToRender, alertType) => {
		if (dataAlerts) {
			if (dataAlerts && dataAlerts[alertType].length > 0) {
				const LazyAlert = React.lazy(() => {
					return import("../../../components/ModalBox/ModalBox");
				});

				const onEraseAlert = () => eraseAlert(alertToRender.id, alertType);

				return (
					<Suspense
						fallback={
							<ModalBox>
								<Loading></Loading>
							</ModalBox>
						}
					>
						<LazyAlert action={onEraseAlert}>
							<div className="top-alert-content">
								<img src={notificationIcon} alt="" />
								<h3>Recuerda</h3>
							</div>
							<div className="bottom-alert-content top-space">
								<h4>¡Tienes una cita programada!</h4>
								<p>
									<span>Día: </span>
									<strong>{moment(alertToRender.fecha).format("DD-MM-YYYY")}</strong>
								</p>
								{alertToRender.hora && (
									<p>
										<span>Hora: </span>
										<strong>{alertToRender.hora}</strong>
									</p>
								)}
							</div>
							<button className="button-app top-space" onClick={onEraseAlert}>
								Aceptar
							</button>
						</LazyAlert>
					</Suspense>
				);
			}
		}
	};

	const renderErrors = () => (error ? <Snackbar text={error} /> : null);

	const renderListClinicSelector = () => {
		return (
			<ModalBox hasCloseButton={true} action={() => setToggleModalLoadData(false)}>
				<div className="clinic_list_modal_container">
					<h2>Hola, {state?.userData?.nombre}</h2>
					<p>Hemos identificado que eres paciente de varias clínicas. Selecciona la clínica de la que quieres ver el contenido:</p>
					<div className="list_btn_container">
						{listOfClinic?.length !== 0 ? listOfClinic.map((clinic) => {
							return (
								<button key={clinic.ClinicId} onClick={() => goToClinic(clinic)} className="button_clinic_selector">
									<span className="button-label">Clínica {clinic.ClinicName}</span>
									<span className="button-icon"></span>
								</button>
							);
						}) : ""}
					</div>
				</div>
			</ModalBox>
		)
	}

	const renderLoadClinicSelected = () => {
		return (<ModalBox hasCloseButton={true} action={() => setToggleModalMoreClinic(false)}>
			<div className="clinic_load_data_modal_container">
				<p>Los datos de <strong>{labelClinicSelected}</strong> se están cargando...</p>
				<Loading></Loading>
			</div>
		</ModalBox>
		)
	}
	/////////////////////////////
	// Renderizado del componente
	/////////////////////////////

	/***
	 *
	 * @return {Component}
	 * @description Renderizado del componente
	 *
	 */

	return loading ? null : (
		<React.Fragment>
			{renderErrors()}

			{dataAlerts !== undefined
				? renderAlerts(dataAlerts.incoming[dataAlerts.incoming.length - 1], "incoming")
				: null}
			{dataAlerts !== undefined && dataAlerts.incoming.length <= 0
				? renderAlerts(dataAlerts.appointments[dataAlerts.appointments.length - 1], "appointments")
				: null}
			{hasMoreClinic && toggleModalMoreClinic ? renderListClinicSelector()
				: null}
			{toggleModalLoadData ? renderLoadClinicSelected()
				: null}
			<h2 className="subtitle">Configura desde aquí tu Área Paciente</h2>

			<div className="cards-container">
				{options.map((option) => {
					return (
						<Card key={option.key}>
							<div className="color-icon">
								<img src={option.icon} alt="Usuario" />
							</div>
							<h3>{option.title}</h3>
							<Link to={option.link} className="button-app primary">
								<span className="button-label">Ver</span>
								<span className="button-icon arrow-right-o"></span>
							</Link>
						</Card>
					);
				})}
			</div>
		</React.Fragment>
	);
};

export default Account;
