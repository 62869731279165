import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Routes wrappers

import PasswordRoute from "../components/PasswordRoute/PasswordRoute";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import PublicRoute from "../components/PublicRoute/PublicRoute";

// Pages


import Account from "../pages/account/Account/Account";
import CalendarHandler from "../pages/account/Calendar/CalendarHandler";
import ClientPoll from "../pages/account/ClientPoll/ClientPoll";
import Conditions from "../pages/auth/Conditions/Conditions";
import Contact from "../pages/account/Contact/Contact";
import ForgotPassword from "../pages/auth/ForgotPassword/ForgotPassword";
import Invoices from "../pages/account/Account/Invoices/Invoices";
import LegalInfo from "../pages/account/Account/Settings/LegalInfo/LegalInfo";
import MakeCall from "../pages/account/Contact/MakeCall/MakeCall";
import MakeConference from "../pages/account/Contact/MakeConference/MakeConference";
import FileUpload from "../pages/account/FileUpload/FileUpload";
import PersonalData from "../pages/account/Account/PersonalData/PersonalData";
import Plan from "../pages/account/Plan/Plan";
import Privacy from "../pages/auth/Privacy/Privacy";
import Reports from "../pages/account/Reports/Reports";
import SetPassword from "../pages/auth/SetPassword/SetPassword";
import Settings from "../pages/account/Account/Settings/Settings";
import SignIn from "../pages/auth/SignIn/SignIn";
import VideoConference from "../pages/conference/VideoConference/VideoConference";


// Others

import { useAuth } from "../provider";
import PlanAmigoPrivacy from "../pages/auth/Privacy/PlanAmigoPrivacy";

const Router = () => {
  const { state } = useAuth();

  

  
  return (
    <Switch>
      <Route exact path={"/intro/privacidad"} component={Privacy} />
      <Route exact path={"/intro/condiciones"} component={Conditions} />
      <Route exact path={"/intro/privacidad-plan-amigo"} component={PlanAmigoPrivacy} />

      <PasswordRoute exact path={"/intro/cambiarpassword"} component={SetPassword} />
      <PublicRoute exact path={"/intro/login/"} component={SignIn} />
      <PublicRoute exact path={"/intro/login/:userToken/:auth"} component={SignIn} />
      <PublicRoute exact path={"/intro/RecuperarPassword"} component={ForgotPassword} />

      {/* Account routes */}
      <PrivateRoute exact path="/Paciente/SubirDocumento" component={FileUpload}/>      
      <PrivateRoute exact path="/Paciente/Cuenta" component={Account}></PrivateRoute>
      <PrivateRoute exact path="/Paciente/Cuenta/Datos" component={PersonalData}></PrivateRoute>
      <PrivateRoute exact path="/Paciente/Cuenta/Facturapresupuesto" component={Invoices}></PrivateRoute>
      <PrivateRoute exact path="/Paciente/Cuenta/Ajustes" component={Settings}></PrivateRoute>
      <Route exact path="/Paciente/Ajustes/informacionlegal" component={LegalInfo}></Route>
      

      {/* Report routes */}

      <PrivateRoute exact path="/Paciente/Informes" component={Reports}></PrivateRoute>;
      
      {/* Calendar routes */}
      
      <PrivateRoute exact path="/Paciente/Calendario" component={CalendarHandler}></PrivateRoute>
      
      {/* Contact routes */}
      
      <PrivateRoute exact path="/Paciente/Contacto" component={Contact}></PrivateRoute>
      <PrivateRoute exact path="/Paciente/Llamada" component={MakeCall}></PrivateRoute>
      <PrivateRoute exact path="/Paciente/Videollamada" component={MakeConference}></PrivateRoute>
      
      {/* Plan route */}
        <PrivateRoute exact path="/Paciente/Plan" component={Plan}></PrivateRoute>;
      {/* Client poll router */}

      <PrivateRoute exact path="/Paciente/EncuestaSatisfaccion" component={ClientPoll}></PrivateRoute>
      
      {/* Videoconference */}

      <Route exact path="/Videoconsulta/:room" component={VideoConference} />

      {!state.isLoggedIn ? <Redirect to="/intro/login" /> : state.user.isPasswordReset ? <Redirect to="/intro/cambiarpassword" /> : <Redirect to="/Paciente/Cuenta" />}
    </Switch>
  );
};

export default Router;
