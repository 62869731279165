import React, { useState, useEffect } from "react";
import logo from "../../assets/images/lg-baviera-color.svg";
import LinkButton from "../LinkButton/LinkButton";
import Updating from "../Updating/Updating";
import { useAuth } from "../../provider";
import "./header.scss";
import * as api from "../../services/services";
import Select from "../Select/Select";
/**
 *
 * @param {string} props.userName
 * @param {function} props.onLogout
 * @param {boolean} props.loading
 *
 * @description Renderizado de la información básica del usuario (nombre)
 * y renderizado del código que albergara la función de logout.
 *
 */

const Header = (props) => {
	const { userName, onLogout, loading } = props;
	const [selectValue, setSelectValue] = useState(undefined);
	const { state, listOfClinic, setUserToken, setIsClinicSelected,setPlanAmigo } = useAuth();
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

	const handleResize = () => {
		setViewportWidth(window.innerWidth);
	};


	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
 
	const changePlanAmigo=async()=>{
		let response = await api.getUserData(state.user);
		await setPlanAmigo(response.planAmigo);
	}
	
	useEffect(() => {
		setUserToken(selectValue);
		localStorage.setItem('userToken', selectValue);
		changePlanAmigo();
	}, [selectValue]);

	return (
		<header className="main-header app-header">
			{viewportWidth <= 540 ? (
				<div className="logo-mobile">
					<img src={logo} alt="Clínica Baviera" />
				</div>
			) : (
				""
			)}
			<div className="header-actions">
				<div className="logout" onClick={onLogout}>
					Cerrar sesión
				</div>
				<LinkButton url={"/Paciente/SubirDocumento"} urlLabel={"Subir documentos"} type={"upload-file-button"} />
				{state.isClinicSelected && listOfClinic.length > 0 ?
					<span className="select_ontainer_in_header">
						<Select
							Options={listOfClinic.map((item) => ({ value: item.UserToken, name: item.ClinicName }))}
							width={"230px"}
							height={"43px"}
							actionOnSelect={(option) => { setSelectValue(option.value); setIsClinicSelected(option.name) }}
						/>
					</span>
					: ""
				}
			</div>
			<div className="hello-text">
				{loading ? (
					<Updating isRefreshing={loading} />
				) : (
					<React.Fragment>
						<p className="greeting">Hola</p>
						<p className="user-name">{userName}</p>
					</React.Fragment>
				)}
			</div>
		</header>
	);
};

export default Header;
